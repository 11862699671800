<template>
  <div>
    <div v-if="avaible">
      <b-row
        align-h="between"
        class="mb-1"
      >
        <b-col>
          <b-button
            variant="warning"
          >
            Filtro
          </b-button>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button
            id="toggle-btn"
            v-b-modal.modal-1
            variant="primary"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Nova Importação</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-table
              :items="planilhas"
              :fields="fields"
            >
              <template #cell(nome)="data">
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  @click="findFilial(data.item.banco, data.item.data_recebimento)"
                >
                  {{ data.item.nome }}
                </b-link>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>

      <b-modal
        id="modal-1"
        title="Basic Modal"
        ok-only
        ok-title="Importar"
        no-close-on-backdrop
        @ok="importarRemessa"
      >
        <b-row>
          <b-col>
            <label for="">Selecione a unidade</label>
            <v-select
              v-model="unidadeSelected"
              label="nome"
              :options="unidades"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <label for="">Selecione o arquivo</label>
            <b-form-file
              v-model="planilha"
              placeholder=""
              drop-placeholder="Arquivo"
            />
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div v-else>
      <h2>Not Provided</h2>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BLink,
  BModal,
  VBModal,
  BFormFile,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import api from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    BLink,
    BModal,
    BFormFile,
    vSelect,
  },
  directives: {
    VBModal,
  },
  data() {
    return {
      endPoint: 'http://192.168.254.204:9000',
      fields: [
        { key: 'nome', label: 'nome - recebimento' },
        { key: 'banco', label: 'Código banco' },
        { key: 'status', label: 'status' },
      ],
      planilhas: [],
      unidades: [],

      unidadeSelected: null,
      planilha: null,
      avaible: false,
    }
  },
  async created() {
    // const { userId } = localStorage
    const userId = '54'
    if (userId === '54') {
      await this.getUnidades()
      await this.getPlanilhas()
      this.avaible = true
    }
  },
  methods: {
    async getUnidades() {
      await api.get(`${this.endPoint}/api/banco/lista`).then(res => {
        const { data } = res
        if (data) {
          this.unidades = data
        }
      })
    },

    async getPlanilhas() {
      await api.get(`${this.endPoint}/api/planilha/lista`).then(res => {
        const { data } = res
        if (data) {
          this.planilhas = []
          data.map(item => {
            this.planilhas.unshift(item)
          })
        }
      })
    },

    async findFilial(banco, pagamento) {
      let filial = null
      this.unidades.map(credor => {
        if (credor.id === banco) {
          filial = credor.num_filial
        }
      })
      const params = {
        banco, pagamento, filial,
      }
      this.$router.push({ name: 'view-remessa-aric', params })
    },

    async importarRemessa() {
      const { unidadeSelected, planilha } = this
      if (unidadeSelected && planilha) {
        const data = new FormData()
        data.append('banco', unidadeSelected.id)
        data.append('planilha', planilha)
        await api.post(`${this.endPoint}/api/importarPlanilha`, data).then(res => {
          this.unidadeSelected = null
          this.planilha = null
          if (res.data === 1) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sucesso',
                icon: 'CheckIcon',
                text: 'Importação Relizada!',
                variant: 'success',
              },
            }, {
              position: 'top-center',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                text: 'Importação não realizada',
                variant: 'danger',
              },
            }, {
              position: 'top-center',
            })
          }
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: 'Importação não realizada',
              variant: 'danger',
            },
          }, {
            position: 'top-center',
          })
        })
        await this.getPlanilhas()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            text: 'Importação não realizada',
            variant: 'danger',
          },
        }, {
          position: 'top-center',
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
